import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { FileUploadDropZoneBasic, FileUploadDropZoneCustomValidation } from "../../examples/components/FileUploadDropZone";
export const query = graphql`
  query FileUploadDropZoneQuery {
    FileUploadDropZone: componentMetadata(
      displayName: { eq: "FileUploadDropZone" }
    ) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  File Upload Dropzone component allows users to upload content from their
  computer or device into the application by dragging a file (or files) into a
  target zone.
    </Overview>
    <CodeBlock title="Example" code={FileUploadDropZoneBasic} noInline={true} mdxType="CodeBlock">
  For a styled drag 'n' drop zone for uploading files use the{" "}
  <code>&lt;FileUploadDropZone&gt;</code> component with an <code>id</code>. The
  callback function <code> onFiles </code> fires when the files are added and it
  contains files along with the current error value.
  <br />
  Uploaded files are validated using the input from props <code> multiple</code>
  ,<code> maxFileCount </code> and <code> maxTotalFileSizeBytes </code> and an
  appropriate error message is displayed if any validation fails.
    </CodeBlock>
    <div className="docs-callout docs-callout-warning p-3 pb-0 rounded-lg">
  <code>FileUploadDropZone</code> component uses modus icons, refer to{" "}
  <a href="/getting-started/">Getting Started</a> page on how to enable them.
    </div>
    <CodeBlock title="Custom validation" code={FileUploadDropZoneCustomValidation} noInline={true} mdxType="CodeBlock">
  <>
    By providing <code> validator </code> prop custom validation can be added as
    shown in the example. The function accepts <code>FileList</code> object and
    it should return null or the error message. The default validation will be
    disabled while using <code> validator</code>.
    <br />
    <span className="font-weight-bold">Note:</span> Below example shows the custom
    validation logic to verify single file upload.
  </>
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="file-upload-zone-accessibility" mdxType="LinkedHeading">
  Accessibility
    </LinkedHeading>
    <p>
  User can interact with File Upload Dropzone through keyboard using the{" "}
  <code>tab</code> key (or <code>shift + tab</code> when tabbing backwards).
  When the drop zone or browse link is in focus, pressing the <code>enter</code>/
  <code>return</code> or <code>space</code> keys will open the default file picker.
    </p>
    <LinkedHeading h="2" className="h1" id="file-upload-zone-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.FileUploadDropZone} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      